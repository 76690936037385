import {
    Action,
    ActionsMenu,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxShadow,
    BoxSpacing,
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentSpacing,
    Form,
    Icon,
    IconTooltip,
    InputText,
    Loadable,
    ModalConfirmMessage,
    StatusLabel,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FC, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Container} from "../../api/model/container/Container";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerField} from "../../api/model/container/ContainerField";
import {ContainerStatus} from "../../api/model/container/ContainerStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile, pathContainerList} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ContainerDetailsHeaderProps = {
    container: Container;
    onEdit: (container: Container) => void;
}

const ContainerDetailsHeader: FC<ContainerDetailsHeaderProps> = ({container: initContainer, onEdit}) => {
    const navigate = useNavigate();
    const {id: containerId} = useParams() as {id: string};
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [container, setContainer] = useState<Container>(new Container());
    const [isEdit, setEdit] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [activeDeleteModal, setActiveDeleteModal] = useState(false);

    useEffect(() => {
        setContainer(initContainer);
    }, [initContainer]);

    const handleEditContainerStatus = async () => {
        try {
            const newContainer = await session.restContainer.updateStatus(container.id, container.status === ContainerStatus.ACTIVE ? ContainerStatus.INACTIVE : ContainerStatus.ACTIVE);
            onEdit(newContainer);
            UIEventManager.alert({text: textContainer("success.update"), severity: AlertSeverity.SUCCESS, isClosable: true});
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF_STATUS}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        }
    };

    const handleEditContainerName = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setSubmitting(true);
            const newContainer = await session.restContainer.update(container);
            onEdit(newContainer);
            UIEventManager.alert({text: textContainer("success.update"), severity: AlertSeverity.SUCCESS, isClosable: true});
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        } finally {
            setEdit(false);
            setSubmitting(false);
        }
    };

    const handleCancelContainerName = () => {
        setContainer(new Container(initContainer));
        setEdit(false);
    };

    const handleDeleteContainer = async () => {
        try {
            await session.restContainer.delete(+containerId);
            navigate(pathContainerList, {replace: true});
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_DELETE_CONF}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
            setActiveDeleteModal(false);
        }
    };

    return (
        <Box radius={BoxRadius.MD} shadow={BoxShadow.BLACK_BIG} spacing={BoxSpacing.LARGE} cssClass="container-header">
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                <FlexContent
                    direction={FlexContentDirection.ROW}
                    alignment={FlexContentAlignment.CENTER}
                    justify={FlexContentJustify.SPACE_BETWEEN}
                >
                    <FlexContent
                        direction={FlexContentDirection.ROW}
                        alignment={FlexContentAlignment.CENTER}
                        spacing={FlexContentSpacing.XSMALL}
                        cssClass="container-header__title"
                    >
                        <Icon name="dns" colorIcon={container.status === ContainerStatus.ACTIVE ? Color.CYAN : Color.GREY} outlined cssClass="container-header__title__icon"/>
                        {isEdit ? (
                            <Form onSubmit={handleEditContainerName}>
                                <FieldBlock>
                                    <InputText
                                        value={container.name}
                                        placeholder={textContainer(`placeholder.${ContainerField.NAME}`)}
                                        onChange={(value) => setContainer((prevState) => new Container({...prevState, [ContainerField.NAME]: value}))}
                                        disabled={isSubmitting}
                                        minLength={2}
                                        required
                                        autoFocus
                                        small
                                    />
                                    <Loadable loading={isSubmitting} loaderOptions={{small: true}}>
                                        <Button
                                            icon={Action.SAVE.icon}
                                            size={ButtonSize.XSMALL}
                                            disabled={!container.name || (container.name === initContainer.name)}
                                        />
                                        <IconTooltip text={textCommon(Action.CANCEL.labelKey)} icon={Action.CLOSE.icon} onClick={handleCancelContainerName}/>
                                    </Loadable>
                                </FieldBlock>
                            </Form>
                        ) : (
                            <>
                                <h1>{container.name}</h1>
                                <Icon {...Action.EDIT.icon} colorIcon={Color.GREY} onClick={() => setEdit(true)}/>
                            </>
                        )}
                    </FlexContent>
                    <FlexContent
                        direction={FlexContentDirection.ROW}
                        alignment={FlexContentAlignment.CENTER}
                        spacing={FlexContentSpacing.MEDIUM}
                    >
                        <StatusLabel
                            icon={{name: "circle", colorIcon: container.status === ContainerStatus.ACTIVE ? Color.GREEN : Color.ORANGE}}
                            label={textContainer(`status.${container.status}`)}
                        />
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                            items={[
                                {label: textCommon(container.status === ContainerStatus.ACTIVE ? Action.DEACTIVATE.labelKey : Action.ACTIVATE.labelKey), onClick: handleEditContainerStatus},
                                {label: textCommon(Action.DELETE.labelKey), onClick: () => setActiveDeleteModal(true), separator: true, critical: true}
                            ]}
                        />
                    </FlexContent>
                </FlexContent>
                <div className="container-header__infos">
                    <div className="container-header__infos__item">
                        <span className="container-header__infos__item__label">{textContainer(`field.${ContainerField.CONTAINER_CONFIG_ID}`)}</span>
                        <span>{container.container_config_id}</span>
                    </div>
                </div>
            </FlexContent>
            <ModalConfirmMessage
                active={activeDeleteModal}
                message={textContainer("modal.delete.container")}
                cancel={{onClick: () => setActiveDeleteModal(false), style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(Action.CANCEL.labelKey)}}
                confirm={{onClick: handleDeleteContainer, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.DELETE.labelKey)}}
            />
        </Box>
    );
};

export default ContainerDetailsHeader;
